import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import { useSiteMetadata } from 'helpers/hooks';

import Button from 'components/UI/Button';
import Keyword from 'components/UI/Keyword';

import styles from './InfluencerHeader.module.scss';

const InfluencerHeader: React.FC = () => {
  const { webApp } = useSiteMetadata();

  const { influencerHeader } = useStaticQuery(
    graphql`
      query {
        influencerHeader: file(relativePath: { eq: "influencer-header.jpg" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
      }
    `,
  );

  return (
    <BackgroundImage
      fluid={influencerHeader.childImageSharp.fluid}
      className={styles.background}
    >
      <div className={styles.header}>
        <div className={styles.banner}>
          <h1 className={styles.title}>
            <FormattedMessage
              id="components.influencer.header.title"
              values={{
                strong: (chunks: React.ReactNode): JSX.Element => <strong>{chunks}</strong>,
              }}
            />
          </h1>
          <div className={styles.keywords}>
            <Keyword>
              <FormattedMessage id="components.influencer.header.keywords.share" />
            </Keyword>
            <Keyword>
              <FormattedMessage id="components.influencer.header.keywords.sponsor" />
            </Keyword>
            <Keyword>
              <FormattedMessage id="components.influencer.header.keywords.manage" />
            </Keyword>
            <Keyword>
              <FormattedMessage id="components.influencer.header.keywords.earn" />
            </Keyword>
          </div>
          <div className={styles.actionBtnContainer}>
            <a href={`${webApp}/influencer/login`}>
              <Button>
                <FormattedMessage id="components.influencer.header.actionButton" />
              </Button>
            </a>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default InfluencerHeader;
