import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import { useSiteMetadata } from 'helpers/hooks';

import Button from 'components/UI/Button';

import styles from './InfluencerFooter.module.scss';

const InfluencerFooter: React.FC = () => {
  const { webApp } = useSiteMetadata();

  return (
    <div className={styles.footer}>
      <h2 className={styles.title}>
        <FormattedMessage id="components.influencer.footer.title" />
      </h2>
      <div className={styles.actionBtnContainer}>
        <a href={`${webApp}/influencer/login`}>
          <Button>
            <FormattedMessage id="components.influencer.footer.actionButton" />
          </Button>
        </a>
      </div>
    </div>
  );
};

export default InfluencerFooter;
