import React from 'react';

import styles from './StepToBecomeDetails.module.scss';

const StepToBecomeDetails: React.FC = ({ children }) => (
  <p className={styles.details}>
    {children}
  </p>
);

export default StepToBecomeDetails;
