import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import InfluencerFooter from './InfluencerFooter';
import InfluencerHeader from './InfluencerHeader';
import StepToBecome from './StepToBecome';

import styles from './Influencer.module.scss';

const Influencer: React.FC = () => (
  <>
    <InfluencerHeader />

    <div className={styles.container}>
      <div className={styles.content}>
        <StepToBecome>
          <StepToBecome.Title>
            <FormattedMessage id="components.influencer.stepsToBecome.addOption.title" />
          </StepToBecome.Title>
          <StepToBecome.Details>
            <FormattedMessage id="components.influencer.stepsToBecome.addOption.details" />
          </StepToBecome.Details>
        </StepToBecome>

        <StepToBecome>
          <StepToBecome.Title>
            <FormattedMessage id="components.influencer.stepsToBecome.createContent.title" />
          </StepToBecome.Title>
          <StepToBecome.Details>
            <FormattedMessage id="components.influencer.stepsToBecome.createContent.details" />
          </StepToBecome.Details>
        </StepToBecome>

        <StepToBecome>
          <StepToBecome.Title>
            <FormattedMessage id="components.influencer.stepsToBecome.inviteAnyone.title" />
          </StepToBecome.Title>
          <StepToBecome.Details>
            <FormattedMessage id="components.influencer.stepsToBecome.inviteAnyone.details" />
          </StepToBecome.Details>
        </StepToBecome>

        <StepToBecome>
          <StepToBecome.Title>
            <FormattedMessage id="components.influencer.stepsToBecome.getUpTo.title" />
          </StepToBecome.Title>
          <StepToBecome.Details>
            <FormattedMessage id="components.influencer.stepsToBecome.getUpTo.details" />
          </StepToBecome.Details>
        </StepToBecome>
      </div>
    </div>

    <InfluencerFooter />
  </>
);

export default Influencer;
