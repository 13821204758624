import React from 'react';

import styles from './StepToBecomeTitle.module.scss';

const StepToBecomeTitle: React.FC = ({ children }) => (
  <h3 className={styles.title}>
    {children}
  </h3>
);

export default StepToBecomeTitle;
