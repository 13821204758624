import React from 'react';
import { Helmet } from 'react-helmet';

import Influencer from 'components/Influencer';
import Layout from 'components/layout';

const InfluencerPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Become an influencer</title>
      <meta name="og:title" content="Become an influencer" />
      <meta name="twitter:title" content="Become an influencer" />
    </Helmet>
    <Influencer />
  </Layout>
);

export default InfluencerPage;
