import React from 'react';

import StepToBecomeDetails from './StepToBecomeDetails';
import StepToBecomeTitle from './StepToBecomeTitle';

import styles from './StepToBecome.module.scss';

interface StepToBecomeComponent extends React.FC {
  Title: typeof StepToBecomeTitle;
  Details: typeof StepToBecomeDetails;
}

const StepToBecome: StepToBecomeComponent = ({ children }) => (
  <div className={styles.step}>
    {children}
  </div>
);

StepToBecome.Title = StepToBecomeTitle;
StepToBecome.Details = StepToBecomeDetails;

export default StepToBecome;
